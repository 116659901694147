<template>
  <div>
    <span v-for="(group, index) in testGroups" :key="index">
      <a
        v-if="currentTable['header'][header]['noEdit'] != true"
        class="tagPellet"
        title="Click to add in Category Filter"
        href="javascript:void(0)"
        @click="triggerFilterChange(group)"
        >{{ group }}</a
      >
      <span v-else>{{ group }}</span>
      <template v-if="testGroups.length > 1 && index < testGroups.length - 1"
        ><span class="ml-2"></span
      ></template>
    </span>
  </div>
</template>
<script>
export default {
  props: ["header", "row", "result", "currentTable"],
  computed: {
    testGroups() {
      let testGroups = this.row[this.header].split(",").filter((item) => item);
      return testGroups;
    },
  },
  methods: {
    triggerFilterChange(newFilter) {
      let filters = this.currentTable.groupFilter;
      if (!Array.isArray(filters)) {
        filters = [];
      }
      let exist = false;
      filters.forEach((element) => {
        if (element.value == newFilter) {
          exist = true;
          return;
        }
      });
      if (!exist) {
        filters.push({ text: newFilter, value: newFilter });

        this.$set(this.currentTable, "groupFilter", filters);
        this.$store.commit("tableChanged", this.currentTable);
      }
    },
  },
};
</script>